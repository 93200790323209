// languageHelper.ts
// utilities for language stuff
import _ from 'lodash';

const ccMap = {
  'en': 'English',
  'ko-KR': '한국어',
  'he-IL': 'עברית'
  // 'he-IL': 'Hebrew'
};

/**
 * Turns countryCode into humanly readable format
 * @param cc
 */
const countryCodeToHumanlyReadable = (cc: string) => {
// countryCodeToHumanlyReadable
  return _.get(ccMap, cc) || cc;
};

export default {
  countryCodeToHumanlyReadable,
};
