








































































































import { Component, Vue } from 'vue-property-decorator';
import Layout from '@/layouts/Layout.vue';
import Localization from '@/services/localization';
import LanguageHelper from '@/services/languageHelper';
import { DISPLAY_LANG } from '@/config/constants';
import { mapGetters } from 'vuex';
import i18next from 'i18next';
import moment from 'moment';
import _ from 'lodash';

@Component({
  components: {
    Layout
  },
  computed: mapGetters(['getPracticeInfo', 'getLocation', 'signedInUser'])
})
export default class SettingsPracticeInformation extends Localization {
  private langugeSelect: any = [];
  private currentLang: any = '';

  public mounted() {
    this.currentLang = i18next.language;
    const languages = (i18next as any).store.data;
    const avilableLang = [];

    const supportedLanguages = _.split(DISPLAY_LANG, ',');
    for (const prop of Object.keys(languages)) {
      if (_.includes(supportedLanguages, prop)) {
        avilableLang.push({
          name: LanguageHelper.countryCodeToHumanlyReadable(prop),
          value: prop
        });
      }
    }
    this.langugeSelect = avilableLang;
  }

  public langChange(lang: any) {
    i18next.changeLanguage(lang);
    moment.locale(lang);
  }
}
